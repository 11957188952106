import React from 'react';
import './Spinner.css';

export default function Spinner(props) {
    if (props.changeStyle) {
        return (
            <div style={{ ...props.changeStyle }} className={`loader ${props.class}`}>Loading...</div>
        )
    } else {
        return <div className={`loader ${props.class}`}>Loading...</div>
    }

}

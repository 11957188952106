import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify';
import Spinner from '../Spinner';
import './AddGalleryModal.css';

ReactModal.setAppElement('#root');

export default function AddGalleryModal(props) {
    const [fileName, setFileName] = useState('*Upload Picture');
    const [currentFile, setCurrentFile] = useState('');
    const [creditValue, setCreditValue] = useState('');

    function onFileChange(event) {
        setFileName(event.target.files[0].name);
        setCurrentFile(event.target.files[0]);
    }

    function onCreditChange(event) {
        setCreditValue(event.target.value);
    }

    function onSubmit() {
        if (!props.loading) {
            if (currentFile !== '') {
                props.createNewGalleryItem(currentFile, creditValue);
            } else {
                toast.error('Must upload a file to create a new gallery item');
            }
        }
    }

    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={props.handleModalClose}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    // width: '500px'
                }
            }}
        >
            <div id="modal">
                <h2>Add an Item</h2>
                <label id="fileUploadButton">
                    <input type="file" accept="image/*" onChange={onFileChange} />
                    {fileName}
                </label>
                <input onChange={onCreditChange} value={creditValue} type="text" placeholder="Credit (Optional)" />
                <div id="submitContainer">
                    <div onClick={onSubmit} id="modalSubmit">Submit</div>
                    {props.loading ? <Spinner class={`small`} /> : null}
                </div>
            </div>
        </ReactModal >
    )
}

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../footer/Footer';
import './Home.css';

export default function Home() {

    useEffect(() => {
        //Scroll the page to the top on load
        window.scrollTo(0, 0);
    })
    return (
        <>
            <div id="homeJumbotron">
                <div id="jumboOverlay">
                    <div id="jumboContent">
                        <h1>Piece By Piece Tile Inc.</h1>
                    </div>
                </div>
            </div>
            <div id="homeContentContainer">
                <div id="content">
                    <div id="firstSection">
                        <h1>MAKING YOUR HOME YOURS PIECE BY PIECE</h1>
                        <div id="firstDivider"></div>
                        <div id="iconBanner">
                            <div className="iconItem">
                                <i className="fas fa-ruler"></i>
                                <h2>We follow YOUR specifications</h2>
                            </div>
                            <div className="iconItem">
                                <i className="fas fa-toolbox"></i>
                                <h2>Using our advanced toolset and skill</h2>
                            </div>
                            <div className="iconItem">
                                <i className="fas fa-house-user"></i>
                                <h2>Only the best work is accepted</h2>
                            </div>
                        </div>
                        <div id="firstSectionPhoto"></div>
                    </div>
                    <div id="secondSection">
                        <NavLink to="gallery/backsplash" className="secondSectionCard card" id="backsplashImg">
                            <div className="overlay">
                                <div>
                                    <div className="homeHeader">BACKSPLASH</div>
                                    <div className="underline"></div>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="gallery/showers" className="secondSectionCard card" id="showersImg">
                            <div className="overlay">
                                <div>
                                    <div className="homeHeader">SHOWERS</div>
                                    <div className="underline"></div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div id="thirdSection">
                        <NavLink to="gallery/fireplaces" className="card" id="fireplacesImg">
                            <div className="overlay">
                                <div>
                                    <div id="fireplacesText" className="homeHeader">FIREPLACES & FLOORS</div>
                                    <div className="underline"></div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

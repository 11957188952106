import './App.css';
import React, { useEffect, useState } from 'react';
import { Route, Switch } from "react-router-dom";
import Navbar from './common/Navbar';
import Home from './home/Home';
import Gallery from './gallery/Gallery';
import Admin from './admin/Admin';
import firebase from 'firebase/app';
import NotFound from './common/404/NotFound';
import Dashboard from './admin/dashboard/Dashboard';
import { toast, ToastContainer } from 'react-toastify';


function App() {

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        //Not logged in
      }
    });
  });

  function handleSignOut() {
    firebase.auth().signOut();
    setLoggedIn(false);
    toast.info("Logged Out", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    })
  }

  return (
    <div className="App">
      <Navbar loggedIn={loggedIn} handleSignOut={handleSignOut} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/gallery/:slug" component={Gallery} />

        {loggedIn ? <Route path="/admin" component={Dashboard} /> : <Route exact path="/admin" component={Admin} />}
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />

    </div>
  );
}

export default App;

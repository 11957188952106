import React, { useState } from 'react'
import DeleteGalleryModal from '../../../common/DeleteGalleryModal/DeleteGalleryModal';
import Spinner from '../../../common/Spinner';

export default function GalleryRow({ item, saveItem, deleteItem, changeToHeader }) {
    const [creditVal, updateCreditVal] = useState(item.credit);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    function inputChange(event) {
        updateCreditVal(event.target.value);
    }

    function onSave() {
        if (!loading && item.credit !== creditVal) {
            setLoading(true);
            saveItem({ id: item.id, updatedProperties: { credit: creditVal }, loadingFunc: setLoading });
        }
    }

    function onDelete() {
        if (!loading) {
            deleteItem(item.id, item.originalImgPath);
            setLoading(true);
        }
    }

    function onHeaderClick() {
        if (!loading) {
            setLoading(true);
            changeToHeader({ id: item.id, updatedProperties: { headerImg: true }, loadingFunc: setLoading }, item.imgPath)
        }
    }

    function handleModalClose() {
        setIsOpen(false);
    }

    function handleDeleteButtonClick() {
        setIsOpen(true);
    }


    return (
        <>
            <div key={item.id} className="editImageCard" style={{ order: item.order }}>
                <div className="editImage" style={{ backgroundImage: `url(${item.imgPath})` }}></div>
                <div className="creditInputContainer gridItem">
                    <p>Credit:</p>
                    <input onChange={inputChange} type="text" value={creditVal} placeholder="Enter name" />
                </div>
                <div></div>
                <div className="saveContainer gridItem">
                    {loading ?
                        <>
                            <div className="saveButton" style={{ width: "70%" }}>Save</div>
                            <Spinner class={`small`} />
                        </>
                        :
                        <div onClick={onSave} className="saveButton">Save</div>
                    }

                </div>
                <div className="gridItem">
                    {item.headerImg ? <div style={{ textAlign: "center", width: "100%" }}>Current Header</div> : <div onClick={onHeaderClick} className="makeHeaderButton">Make Header</div>}
                </div>
                <div className="gridItem">
                    <div onClick={handleDeleteButtonClick} className="deleteImageButton">Delete</div>
                </div>
            </div>
            <DeleteGalleryModal confirmDelete={onDelete} isOpen={isOpen} handleModalClose={handleModalClose} />
        </>
    )
}

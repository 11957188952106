import React, { useEffect } from 'react';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import './Dashboard.css';
import About from './About/About.js';
import HomeEdit from './HomeEdit/HomeEdit.js';
import GalleryEdit from './GalleryEdit/GalleryEdit.js';

export default function Dashboard() {
    let match = useRouteMatch();

    useEffect(() => {
        //Scroll the page to the top on load
        window.scrollTo(0, 0);
    })
    return (
        <div id="dashboard-container">
            <div id="sidebar">
                <div className="sidebarLink">
                    <NavLink className="sidelink" activeClassName="is-active" to={`${match.url}`}>About</NavLink>
                    {/* <div className="arrow-left"></div> */}
                </div>

                {/* Uncomment when/if a home edit has been made */}
                {/* <div className="sidebarLink">
                    <NavLink className="sidelink" activeClassName="is-active" to={`${match.url}/home`}>Home</NavLink>
                    <div className="arrow-left"></div>
                </div> */}

                <div className="sidebarLink">
                    <NavLink className="sidelink" activeClassName="is-active" to={`${match.url}/galleryEdit/backsplash`}>Backsplash</NavLink>
                    <div className="arrow-left"></div>
                </div>
                <div className="sidebarLink">
                    <NavLink className="sidelink" activeClassName="is-active" to={`${match.url}/galleryEdit/showers`}>Showers</NavLink>
                    <div className="arrow-left"></div>
                </div>
                <div className="sidebarLink">
                    <NavLink className="sidelink" activeClassName="is-active" to={`${match.url}/galleryEdit/fireplaces`}>Fireplaces</NavLink>
                    <div className="arrow-left"></div>
                </div>
            </div>
            <div id="dashboard-child">
                <Switch>
                    <Route exact path={`${match.path}`} component={About} />
                    <Route path={`${match.path}/home`} component={HomeEdit} />
                    <Route path={`${match.path}/galleryEdit/:slug`} component={GalleryEdit} />
                </Switch>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import AddGalleryModal from '../../../common/AddGalleryModal/AddGalleryModal';
import Spinner from '../../../common/Spinner';
import * as galleryActions from '../../../redux/actions/galleryActions';
import GalleryCard from './GalleryCard';
import './GalleryEdit.css';
import GalleryRow from './GalleryRow';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


function GalleryEdit(props) {

    const slug = props.match.params.slug;
    const history = useHistory();
    const [headerImgPath, setheaderImgPath] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [styleType, setStyleType] = useState({ card: true, pixels: '0px' });

    function validSlug(slug) {
        if (slug === "backsplash" || slug === "showers" || slug === "fireplaces") {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (validSlug(slug)) {
            if (props.data.length === 0) {
                props.loadData(slug);
            } else {
                props.data.forEach(item => {
                    if (item.headerImg) {
                        setheaderImgPath(item.imgPath);
                    }
                })
            }
        } else {
            history.push('../../notFound');
        }
    }, [history, slug, props]);

    function handleModalClose() {
        setIsOpen(false)
    }

    function handleModalOpen() {
        setIsOpen(true);
    }

    function createNewGalleryItem(file, credit) {
        // If the file has any spaces or parenthesis in its name it will not display
        // So we get rid of them :)
        Object.defineProperty(file, "name", {
            writable: true,
            value: file.name.replace(/ /g, "")
        });
        file.name = file.name.replace(/\(/g, "");
        file.name = file.name.replace(/\)/g, "");
        props.addData(file, credit, slug, props.data.length + 1, handleModalClose);
    }

    function deleteGalleryItem(item, imgPath) {
        //If the item is not the last item we need to update the order property
        console.log(Number(item.order));
        console.log(props.data.length);
        if (Number(item.order) < props.data.length) {
            let changedItems = [];
            let deletedIndex;
            let changedData = JSON.parse(JSON.stringify(props.data)).filter((changedItem, index) => {
                if (changedItem.id === item.id) {
                    deletedIndex = index;
                }
                return changedItem.id !== item.id;
            });

            for (let i = 0; i < changedData.length; i++) {
                changedData[i].order = i + 1;
            }

            for (let i = deletedIndex; i < changedData.length; i++) {
                changedItems.push({ ...changedData[i], order: i + 1 });
            }
            props.changeGalleryOrder(changedItems, changedData, slug);
        }
        //The actual deletion of the item
        props.deleteItem(item.id, slug, imgPath);
    }

    function saveItem(newItem) {
        props.updateItem(newItem, slug);
    }

    function updateHeaderImg(newHeader, newHeaderUrl) {
        let oldHeader = props.data.filter(item => {
            return item.headerImg;
        });
        props.updateGalleryHeader(
            { id: oldHeader[0].id, updatedProperties: { headerImg: false }, setHeaderState: () => { setheaderImgPath(newHeaderUrl) } },
            newHeader,
            slug
        );
    }

    function changeStyleType() {
        if (styleType.card) {
            setStyleType({ card: false, pixels: "50px" });
        } else {
            setStyleType({ card: true, pixels: '0px' });
        }
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        let changedItems = [];
        let changedData = JSON.parse(JSON.stringify(props.data));

        // We are given the destination index (Where the item is dragged to) - result.destination.index
        // the index the item came from - result.source.index
        // the id of the item - result.draggableId

        changedItems.push({ ...props.data[result.source.index], order: result.destination.index + 1 });
        const [reorderedItem] = changedData.splice(result.source.index, 1);
        changedData.splice(result.destination.index, 0, reorderedItem);

        for (let i = 0; i < changedData.length; i++) {
            changedData[i].order = i + 1;
        }

        if (result.source.index < result.destination.index) {
            // The item moved forward
            for (let i = result.source.index + 1; i <= result.destination.index; i++) {
                changedItems.push({ ...props.data[i], order: i });
            }
        } else {
            //The item moved backward
            for (let i = result.source.index - 1; i >= result.destination.index; i--) {
                changedItems.push({ ...props.data[i], order: i + 2 });
            }
        }

        // ChangedItems is for the database
        // ChangedData is for the front end state

        props.changeGalleryOrder(changedItems, changedData, slug);
    }

    return (
        <>
            {props.data.length === 0 ? <Spinner changeStyle={{ position: 'absolute', top: '33%', left: '50%', transform: 'translate(-50%, -50%)' }} /> :
                <>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <div id="headerTop">
                            <div onClick={changeStyleType} id="viewButtonsContainer">
                                <div id="selectedBox" style={{ left: styleType.pixels }}></div>
                                <div id="cardsButton">
                                    <div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                    </div>
                                </div>
                                <div id="rowsButton">
                                    <div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                        <div className="box"></div>
                                    </div>
                                </div>
                            </div>
                            <div>{slug.toUpperCase()} HEADER IMAGE</div>
                        </div>
                        <div id="HeaderEdit">
                            <div id="HeaderImg" style={{ backgroundImage: `url(${headerImgPath})` }}></div>
                        </div>
                        {styleType.card ?
                            <div id="galleryImageEditContainer">
                                {props.data.map(item => (
                                    <GalleryCard
                                        key={item.id}
                                        item={item}
                                        deleteItem={deleteGalleryItem}
                                        saveItem={saveItem}
                                        changeToHeader={updateHeaderImg}
                                    />
                                ))}
                            </div>
                            :
                            <Droppable droppableId="galleryItems">
                                {(provided) => (
                                    <div id="galleryImageRow" {...provided.droppableProps} ref={provided.innerRef}>
                                        {props.data.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(providedd) => (
                                                    <div
                                                        ref={providedd.innerRef}
                                                        {...providedd.draggableProps}
                                                        {...providedd.dragHandleProps}>
                                                        <GalleryRow
                                                            item={item}
                                                            deleteItem={deleteGalleryItem}
                                                            saveItem={saveItem}
                                                            changeToHeader={updateHeaderImg}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        }
                        <div onClick={handleModalOpen} className="addButton">+</div>
                        <AddGalleryModal
                            createNewGalleryItem={createNewGalleryItem}
                            loading={props.loading.addItem}
                            isOpen={isOpen}
                            handleModalClose={handleModalClose}
                        />
                    </DragDropContext>
                </>
            }

        </>
    )
}

const mapDispatchToProps = {
    loadData: galleryActions.loadData,
    addData: galleryActions.addGalleryItem,
    deleteItem: galleryActions.deleteGalleryItem,
    updateItem: galleryActions.updateItem,
    updateGalleryHeader: galleryActions.updateGalleryHeader,
    changeGalleryOrder: galleryActions.changeGalleryOrder
}

function mapStateToProps(state, ownProps) {
    const slug = ownProps.match.params.slug;
    if (slug === "backsplash" || slug === "showers" || slug === "fireplaces") {
        return {
            data: state.gallery[slug],
            loading: state.loading
        }
    }
    return { data: [], loading: state.loading }
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryEdit)
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Spinner from '../common/Spinner';
import Footer from '../footer/Footer';
import * as galleryActions from '../redux/actions/galleryActions';
import './Gallery.css';

function Gallery(props) {
    const history = useHistory();
    const slug = props.match.params.slug;
    const [headerImgPath, setheaderImgPath] = useState('');

    function validSlug(slug) {
        if (slug === "backsplash" || slug === "showers" || slug === "fireplaces") {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (validSlug(slug)) {
            if (props.data.length === 0) {
                props.loadData(slug);
            } else {
                props.data.forEach((item) => {
                    if (item.headerImg) {
                        setheaderImgPath(item.imgPath);
                    }
                })
            }
        } else {
            history.push('../../notFound');
        }

        //Scroll the page to the top on load
        window.scrollTo(0, 0);
    });

    return (
        <>
            {props.data.length === 0 ? <Spinner changeStyle={{ marginTop: '300px' }} /> :
                <>
                    <div id="jumboHeader" style={{ backgroundImage: `url(${headerImgPath})` }}>
                        <div>
                            <div id="wordsContainer">
                                {/* This is adding the dynamic header letters */}
                                {slug.split("").map((letter, index) => {
                                    if (index === 0) {
                                        return (<span key={Math.random() + index} id={`letter${index + 1}`}>{letter.toUpperCase()}</span>);
                                    } else {
                                        return (<span key={Math.random() + index} id={`letter${index + 1}`}>{letter}</span>);
                                    }
                                })}
                                {slug === "fireplaces" ? " & floors".split("").map((letter, index) => {
                                    return (<span key={Math.random() + index} id={`letter${index + slug.length + 1}`}>{letter}</span>);
                                }) : null}
                            </div>
                            <div className="divider"></div>
                        </div>
                    </div>
                    {/* Adding images */}
                    <div id="picsContainer">
                        {props.data.map((item) => (
                            <div key={item.id} className="imgContainer" style={{ backgroundImage: `url(${item.imgPath})`, order: item.order }}>
                                <div className="imageOverlay">
                                    {item.credit === "" ? <div></div> : <div>Credit: {item.credit}</div>}
                                </div>
                            </div>
                        )
                        )}
                    </div>
                    <Footer />
                </>
            }
        </>
    )
}

const mapDispatchToProps = {
    loadData: galleryActions.loadData
};

function mapStateToProps(state, ownProps) {
    const slug = ownProps.match.params.slug;
    if (slug === "backsplash" || slug === "showers" || slug === "fireplaces") {
        return {
            data: state.gallery[slug]
        }
    }
    return { data: [] };
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function authReducer(state = initialState.auth, action) {
    switch (action.type) {
        case types.LOGGED_IN:
            return { user: action.user }
        case types.LOGGED_OUT:
            return {}
        default:
            return state;
    }
}
import * as types from './actionTypes';
import firebase from 'firebase/app';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';


// Helper functions

function recievedAllImgPaths(items) {
    let condition = true;
    items.forEach(item => {
        if (typeof item.imgPath !== "string" || item.imgPath.includes("gs://")) {
            condition = false;
        }
    });
    return condition;
}


//Thunk dispatch functions

function loadSpecificData(dispatch, whichData, actionType) {
    let db = firebase.firestore();
    return db.collection(`gallery-data/${whichData}/items`).orderBy('order', 'asc').get().then((snapShot) => {
        let data = [];
        snapShot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() })
        });
        data.forEach((item) => {
            firebase.storage().refFromURL(item.imgPath).getDownloadURL().then(url => {
                item.originalImgPath = item.imgPath;
                item.imgPath = url;
                if (recievedAllImgPaths(data)) {
                    dispatch({ type: actionType, data });
                }
                return url;
            });
        })

    })
}

function addSpecificData(dispatch, file, credit, whichGallery, actionType, order, handleClose) {
    dispatch({ type: types.CHANGE_LOAD_ADD_ITEM });
    let db = firebase.firestore();
    let begImagePath = whichGallery;
    if (whichGallery === "fireplace&floors") {
        begImagePath = "fireplaces";
    }

    const ref = firebase.storage().ref(begImagePath + '/' + uuid() + file.name);

    return ref.put(file).then((snapshot) => {
        return db.collection(`gallery-data/${whichGallery}/items`).add({ imgPath: String(ref), credit, order }).then((docRef) => {
            return snapshot.ref.getDownloadURL().then(url => {
                dispatch({ type: actionType, file: url, credit, order, id: docRef.id, originalImgPath: String(ref) });
                toast.info('Image added!');
                dispatch({ type: types.CHANGE_LOAD_ADD_ITEM });
                handleClose();
            }).catch(err => {
                console.log(err);
                toast.error(err.message);
                dispatch({ type: types.CHANGE_LOAD_ADD_ITEM });
            })
        }).catch(err => {
            console.log(err);
            toast.error(err.message);
            dispatch({ type: types.CHANGE_LOAD_ADD_ITEM });
        })
    }).catch(err => {
        console.log(err);
        toast.error(err.message);
        dispatch({ type: types.CHANGE_LOAD_ADD_ITEM });
    })

}

function deleteSpecificItem(dispatch, id, whichGallery, imgPath, actionType) {
    let db = firebase.firestore();

    db.collection(`gallery-data/${whichGallery}/items`).doc(id).delete().then(res => {
        let fullImagePath = imgPath.split("")
        fullImagePath.splice(0, 31);
        fullImagePath = fullImagePath.join("");
        const ref = firebase.storage().ref();
        ref.child(`${fullImagePath}`).delete().catch(err => {
            toast.error(`Image did not delete properly`, { autoClose: 4000 });
        });
        dispatch({ type: actionType, id: id });
        toast.info(`Item Deleted!`);
    }).catch(err => {
        toast.error(`Error deleting item: ${err.message}`, { autoClose: 4000 });
    })

}

function updateSpecificItem(dispatch, data, whichGallery, actionType) {
    let db = firebase.firestore();

    db.collection(`gallery-data/${whichGallery}/items`).doc(data.id).update(data.updatedProperties)
        .then(res => {
            dispatch({ type: actionType, data });
            toast.info(`Item Updated!`);
            data.loadingFunc(false);
        }).catch(err => {
            toast.error(`Error updating item! ${err.message}`, { autoClose: 4000 });
        })
}

function updateSpecificGalleryHeader(dispatch, oldHeader, newHeader, whichGallery, actionType) {
    let db = firebase.firestore();

    db.collection(`gallery-data/${whichGallery}/items`).doc(oldHeader.id).update(oldHeader.updatedProperties)
        .then(res => {
            dispatch({ type: actionType, data: oldHeader });
            db.collection(`gallery-data/${whichGallery}/items`).doc(newHeader.id).update(newHeader.updatedProperties)
                .then(res => {
                    dispatch({ type: actionType, data: newHeader });
                    toast.info(`Header Updated!`);
                    newHeader.loadingFunc(false);
                    oldHeader.setHeaderState();
                }).catch(err => {
                    toast.error(`Error updating header! ${err.message}`, { autoClose: 4000 });
                })
        }).catch(err => {
            toast.error(`Error updating item! ${err.message}`, { autoClose: 4000 });
        })
}

function changeSpecificGalleryOrder(dispatch, changedItems, changedData, whichGallery, actionType) {
    let db = firebase.firestore();

    dispatch({ type: actionType, changedData });
    changedItems.forEach(item => {
        console.log(item);
        db.collection(`gallery-data/${whichGallery}/items`).doc(item.id).update({ order: item.order }).catch(err => {
            toast.error(`Error updating the order of the gallery items. Please refresh the page.`, { autoClose: 4000 });
            console.log(err);
        })
    })
}

// Actions being called

export function loadData(whichData) {
    return function (dispatch) {
        switch (whichData) {
            case "backsplash":
                loadSpecificData(dispatch, whichData, types.LOADED_BACKSPLASH_DATA);
                break;
            case "showers":
                loadSpecificData(dispatch, whichData, types.LOADED_SHOWER_DATA);
                break;
            case "fireplaces":
                loadSpecificData(dispatch, "fireplace&floors", types.LOADED_FIREPLACE_DATA);
                break;
            default: ;
        }
    }
}

export function addGalleryItem(file, credit, whichGallery, order, handleClose) {
    return function (dispatch) {
        switch (whichGallery) {
            case "backsplash":
                addSpecificData(dispatch, file, credit, whichGallery, types.ADD_BACKSPLASH_DATA, order, handleClose);
                break;
            case "showers":
                addSpecificData(dispatch, file, credit, whichGallery, types.ADD_SHOWER_DATA, order, handleClose);
                break;
            case "fireplaces":
                addSpecificData(dispatch, file, credit, "fireplace&floors", types.ADD_FIREPLACE_DATA, order, handleClose);
                break;
            default: ;
        }
    }
}

export function deleteGalleryItem(id, whichGallery, imgPath) {
    return function (dispatch) {
        switch (whichGallery) {
            case "backsplash":
                deleteSpecificItem(dispatch, id, whichGallery, imgPath, types.DELETE_BACKSPLASH_ITEM);
                break;
            case "showers":
                deleteSpecificItem(dispatch, id, whichGallery, imgPath, types.DELETE_SHOWER_ITEM);
                break;
            case "fireplaces":
                deleteSpecificItem(dispatch, id, "fireplace&floors", imgPath, types.DELETE_FIREPLACE_ITEM);
                break;
            default: ;
        }
    }
}

export function updateItem(data, whichGallery) {
    return function (dispatch) {
        switch (whichGallery) {
            case "backsplash":
                updateSpecificItem(dispatch, data, whichGallery, types.UPDATE_BACKSPLASH_ITEM);
                break;
            case "showers":
                updateSpecificItem(dispatch, data, whichGallery, types.UPDATE_SHOWER_ITEM);
                break;
            case "fireplaces":
                updateSpecificItem(dispatch, data, "fireplace&floors", types.UPDATE_FIREPLACE_ITEM);
                break;
            default: ;
        }
    }
}

export function updateGalleryHeader(oldHeader, newHeader, whichGallery) {
    return function (dispatch) {
        switch (whichGallery) {
            case "backsplash":
                updateSpecificGalleryHeader(dispatch, oldHeader, newHeader, whichGallery, types.UPDATE_BACKSPLASH_ITEM);
                break;
            case "showers":
                updateSpecificGalleryHeader(dispatch, oldHeader, newHeader, whichGallery, types.UPDATE_SHOWER_ITEM);
                break;
            case "fireplaces":
                updateSpecificGalleryHeader(dispatch, oldHeader, newHeader, "fireplace&floors", types.UPDATE_FIREPLACE_ITEM);
                break;
            default: ;
        }
    }
}

export function changeGalleryOrder(changedItems, changedData, whichGallery) {
    return function (dispatch) {
        switch (whichGallery) {
            case "backsplash":
                changeSpecificGalleryOrder(dispatch, changedItems, changedData, whichGallery, types.CHANGE_BACKSPLASH_ORDER);
                break;
            case "showers":
                changeSpecificGalleryOrder(dispatch, changedItems, changedData, whichGallery, types.CHANGE_SHOWER_ORDER);
                break;
            case "fireplaces":
                changeSpecificGalleryOrder(dispatch, changedItems, changedData, "fireplace&floors", types.CHANGE_FIREPLACE_ORDER);
                break;
            default: ;
        }
    }
}
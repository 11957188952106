import { combineReducers } from 'redux';
import gallery from './galleryReducers';
import authReducer from './authReducer';
import loadingReducer from './loadingReducer';

const rootReducer = combineReducers({
    gallery: gallery,
    auth: authReducer,
    loading: loadingReducer
});

export default rootReducer;
import React, { useState } from 'react';
import './Admin.css';
// import { useHistory } from "react-router-dom";
import * as authActions from '../redux/actions/authActions';
import { connect } from 'react-redux';


function Admin(props) {

    const [formData, setFormData] = useState({ email: '', password: '' });

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    function onSubmit(event) {
        event.preventDefault();
        props.login(formData);
    }

    return (
        <div id="adminContainer">
            <div id="leftImg"></div>
            <div id="formContainer">
                <div>
                    <h1>Login to Piece by Piece Tile</h1>
                    <form onSubmit={onSubmit}>
                        <input name="email" type="email" placeholder="Email:" onChange={handleChange} value={formData.email} />
                        <input name="password" type="password" placeholder="Password:" onChange={handleChange} value={formData.password} />
                        <input id="submitButton" type="submit" value="Login" />
                    </form>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state, ownProps) {
    return { data: [] }
}

export default connect(mapStateToProps, { login: authActions.logIn })(Admin);
export const CHECK_LOGGED_IN = "CHECK_LOGGED_IN";
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const LOADED_BACKSPLASH_DATA = "LOADED_BACKSPLASH_DATA";
export const LOADED_SHOWER_DATA = "LOADED_SHOWER_DATA";
export const LOADED_FIREPLACE_DATA = "LOADED_FIREPLACE_DATA";
export const ADD_BACKSPLASH_DATA = "ADD_BACKSPLASH_DATA";
export const ADD_SHOWER_DATA = "ADD_SHOWER_DATA";
export const ADD_FIREPLACE_DATA = "ADD_FIREPLACE_DATA";
export const CHANGE_LOAD_ADD_ITEM = "CHANGE_LOAD_ADD_ITEM";
export const DELETE_BACKSPLASH_ITEM = "DELETE_BACKSPLASH_ITEM";
export const DELETE_SHOWER_ITEM = "DELETE_SHOWER_ITEM";
export const DELETE_FIREPLACE_ITEM = "DELETE_FIREPLACE_ITEM";
export const UPDATE_BACKSPLASH_ITEM = "UPDATE_BACKSPLASH_ITEM";
export const UPDATE_SHOWER_ITEM = "UPDATE_SHOWER_ITEM";
export const UPDATE_FIREPLACE_ITEM = "UPDATE_FIREPLACE_ITEM";
export const CHANGE_BACKSPLASH_ORDER = "CHANGE_BACKSPLASH_ORDER";
export const CHANGE_SHOWER_ORDER = "CHANGE_SHOWER_ORDER";
export const CHANGE_FIREPLACE_ORDER = "CHANGE_FIREPLACE_ORDER";

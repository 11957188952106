import React from 'react'
import 'react-toastify/dist/ReactToastify.min.css';
import './NotFound.css';

export default function NotFound() {

    return (
        <>
            <div id="a404Container">
                <p>Page Not Found</p>
            </div>
        </>
    )
}

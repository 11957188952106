import React from 'react'
import './About.css';

export default function About() {
    return (
        <div id="aboutContainer">
            <div id="head">
                <h1>PXP Dashboard</h1>
                <p>This page contains helpful resources on how to use this dashboard</p>
            </div>
            <div id="quickLinksContainer" className="contentSection">
                <h3>Quick Links</h3>
                <div id="quickLinks">
                    <a href="#addingImages">How to add a new image to a gallery</a>
                    <a href="#imageInfo">What can be done to an image after its been added?</a>
                    <a href="#imageOptimization">How to optimize my images</a>
                    <a href="#reorderImages">How to re-order images</a>
                </div>
            </div>
            <div id="addingImages" className="contentSection">
                <h3>How to add a new image to a gallery</h3>
                <p>Adding new images to a gallery is one of the main features to this dashboard. If you want to add an image, make sure you have clicked on the page you would like to add the image to on the left-hand navigation bar. Once you have clicked on one of those pages there will be a button in the bottom right hand side of your screen with a little plus icon. Click on that button to pull up the form to add an image.</p>
                <p>Once the form is up, you can easily navigate away from the form by clicking anywhere outside of the form if needed. Click on the button that says "*Upload Picture." After a couple seconds a window should pop up that displays the files that are on your computer. Find the picture that you would like to upload. Click on it and select "open." If successful you should see the name of the image on the button. If that is not the image you meant to select, click on the button again and select the correct image.</p>
                <p><strong>Important Note:</strong> It is recommended that you only upload images that have been optimized for web viewing. This means that the image takes up the least amount of data possible. The reason for this is because we want to prevent long load times. When an image takes up more than 1MB the image may take a long time to load on a users computer/smart phone. Click <a href="#imageOptimization">here</a> to learn how to optimize your images.</p>
                <p>To the right of the upload button is a space to add the name of the employee who worked on the specific project you are uploading. If you do not wish to add a name then leave this blank. Once done go ahead and click the "Submit" button. The image will then be uploaded to the database. This may take a few moments. Once complete you should see your new image at the bottom of the gallery. You can also go and see it on the actual page that users will see by clicking on the corresponding gallery in the top navigation bar.</p>
            </div>
            <div id="imageInfo" className="contentSection">
                <h3>What can be done to an image after its been added?</h3>
                <p>Once you have added an image to a gallery you can do one of three things with it. You can either delete the image, make the image the header image, or change the credit given for that job. Deleting the image is pretty intuitive. Simply click on the delete button that is connected to the image and confirm that you would like to delete the image.</p>
                <p>The button that is labeled "Make header" will make that image the one that is displayed as the background when a user first clicks on that gallery. This image will still appear in the gallery as well. Lastly, to change the credit you can change the name that is right below the image. Once done make sure you click the save button. If you do not click this button then the credit will not actually change.</p>
            </div>
            <div id="imageOptimization" className="contentSection">
                <h3>How to optimize my images</h3>
                <p>Optimizing images is super important when creating a clean website. If an image is too large then it will take longer to load which creates a feeling of slowness to the user. Here is one way to optimize your images:</p>
                <ul>
                    <li>Start by going to pixlr.com - This is a free online image editor</li>
                    <li>If asked which photo editor to open click on the one that says <em>PIXLR X</em></li>
                    <li>Click on "OPEN IMAGE"</li>
                    <li>Select the image you want to optimize</li>
                    <li>Once the image is open in pixlr click on the save button in the bottom right hand side of the screen</li>
                    <li>Notice the area where it says "size: ..." - This will be right in between "File type" and "Quality"</li>
                    <li>If it says your image is over 1MB then it is too large. Edit the width of the image to be smaller(The height will automatically adjust to keep the images aspect ratio)</li>
                    <li>Once the image the image is less than 1MB go ahead and download the image.</li>
                    <li>You can now come to this website and upload the image to a gallery. Click <a href="#addingImages">here</a> for details on how to upload an image to the website.</li>
                </ul>
                <em>If you plan to make an image the header image it is okay to have an image that is over 1MB to allow for a larger image size.</em>
            </div>
            <div id="reorderImages" className="contentSection">
                <h3>How to re-order images</h3>
                <p><em>You can only resize images when you are using a computer(will not work on a smart phone).</em></p>
                <p>To re-order images you will first need to change the view to show your images in rows. In the top left of any of the gallery editing pages you will see some gray icons. The first icon resembles "card view" and the second icon resembles "row view." Click on the second icon. Immediatly you should be able to scroll down and see your images in rows instead of cards.</p>
                <p><em>If you do not see these icons and you are on a computer then this means the window is too small. Try making the window full screen.</em></p>
                <p>Once in this view you can now click and drag any of the images to re-order them.</p>
            </div>
        </div>
    )
}

import React, { useState } from 'react';
import './navbar.css';
import logo from '../../images/pxpLogoPlain.png';
import { NavLink } from 'react-router-dom';

export default function Navbar(props) {
    const [mobileNavDisplay, setMobileNavDisplay] = useState('none')

    function showHideMobileNav() {
        if (mobileNavDisplay === 'none') {
            setMobileNavDisplay('block');
        } else {
            setMobileNavDisplay('none');
        }
    }

    return (
        <div id="logo">
            <div className="nav">
                <div id="navContainer">
                    <img alt='logo' src={logo} />
                    <div className="navItem">
                        <NavLink to="/" exact>Home</NavLink>
                        <div className="inputBorder"></div>
                    </div>
                    <div className="navItem">
                        <NavLink to="/gallery/backsplash" exact>Backsplash</NavLink>
                        <div className="inputBorder"></div>
                    </div>
                    <div className="navItem">
                        <NavLink to="/gallery/showers" exact>Showers</NavLink>
                        <div className="inputBorder"></div>
                    </div>
                    <div className="navItem">
                        <NavLink to="/gallery/fireplaces" exact>Fireplaces & Floors</NavLink>
                        <div className="inputBorder"></div>
                    </div>
                    {props.loggedIn ?
                        <div className="navItem">
                            <a onClick={() => { props.handleSignOut() }}>Sign out</a>
                            <div className="inputBorder"></div>
                        </div>
                        : null}
                </div>
                <div id="mobileNav">
                    <img alt='logo' src={logo} />
                    <div id="hamburger" onClick={showHideMobileNav}>
                        <i className="fa fa-bars"></i>
                    </div>
                </div>
            </div>
            <div id="mobileNavLinks" style={{ display: mobileNavDisplay }}>
                <NavLink to="/" className="mobileNavLink">
                    Home
                </NavLink>
                <NavLink to="/gallery/backsplash" className="mobileNavLink">
                    Backsplash
                </NavLink>
                <NavLink to="/gallery/showers" className="mobileNavLink">
                    Showers
                </NavLink>
                <NavLink to="/gallery/fireplaces" className="mobileNavLink">
                    Fireplaces & Floors
                </NavLink>
            </div>
        </div>
    )
}

const initialState = {
    gallery: {
        backsplash: [],
        showers: [],
        fireplaces: []
    },
    auth: {},
    loading: {
        addItem: false
    }
}

export default initialState;
import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function loadingReducer(state = initialState.loading, action) {
    switch (action.type) {
        case types.CHANGE_LOAD_ADD_ITEM:
            return { ...state, addItem: !state.addItem };
        default:
            return state;
    }
}
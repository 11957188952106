import React from 'react'
import ReactModal from 'react-modal'
import './DeleteGalleryModal.css'

export default function DeleteGalleryModal(props) {
    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={props.handleModalClose}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '500px'
                }
            }}
        >
            <div id="modal">
                <h2>Delete the item?</h2>
                <div id="deleteButtonContainer">
                    <div className="deleteModalButton" onClick={props.confirmDelete}>Delete</div>
                    <div className="cancelButton" onClick={props.handleModalClose}>Cancel</div>
                </div>
            </div>
        </ReactModal>
    )
}

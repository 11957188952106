import React, { useState } from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';
import Spinner from '../common/Spinner';
import axios from 'axios';

export default function Footer() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        content: ''
    });
    const [formErrors, setFormErrors] = useState({
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false,
        content: false
    });
    let [successMessage, setSuccessMessage] = useState('');
    let [errorMessage, setErrorMessage] = useState('');
    let [loading, setLoading] = useState(false);
    let phoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    let date = new Date();;
    let year = date.getFullYear();


    function handleOnFormChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
        Object.values(formErrors).forEach(possibleError => {
            //reset the errors if there is one
            if (possibleError) {
                setFormErrors({
                    firstName: false,
                    lastName: false,
                    phoneNumber: false,
                    email: false,
                    content: false
                });
                setErrorMessage('')
            }
        })


    }

    function formIsValid() {
        if (formData.firstName === '') {
            return "FIRST_NAME";
        }
        else if (formData.lastName === '') {
            return "LAST_NAME";
        }
        else if (!phoneRegEx.test(formData.phoneNumber)) {
            return "PHONE_NUMBER"
        }
        else if (!emailRegEx.test(formData.email)) {
            return "EMAIL"
        }
        else if (formData.content === '') {
            return "CONTENT"
        } else {
            return true;
        }
    }

    function submitForm() {
        let currentFormValidated = formIsValid();
        if (currentFormValidated === true) {
            //Send the form data
            setLoading(true);
            let postData = { ...formData, content: `Name: ${formData.firstName} ${formData.lastName}\nPhone Number: ${formData.phoneNumber}\nEmail: ${formData.email}\n\n${formData.content}` };
            axios.post("https://pxptileserver.herokuapp.com/sendEmail", postData).then(data => {
                setSuccessMessage("Form Successfully Submitted!")
                setLoading(false);
                setFormData({
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    email: '',
                    content: ''
                });
            }).catch(err => {
                setErrorMessage("There was an error submitting the form. Please refresh the page and try again.");
                setLoading(false);
            })

        } else {
            //Show helpful error message
            switch (currentFormValidated) {
                case "FIRST_NAME":
                    setFormErrors(prevFormErrors => ({
                        ...prevFormErrors,
                        firstName: true
                    }));
                    setErrorMessage("Enter your first name");
                    break;
                case "LAST_NAME":
                    setFormErrors(prevFormErrors => ({
                        ...prevFormErrors,
                        lastName: true
                    }));
                    setErrorMessage("Enter your last name");
                    break;
                case "PHONE_NUMBER":
                    setFormErrors(prevFormErrors => ({
                        ...prevFormErrors,
                        phoneNumber: true
                    }));
                    setErrorMessage("Enter your phone number");
                    break;
                case "EMAIL":
                    setFormErrors(prevFormErrors => ({
                        ...prevFormErrors,
                        email: true
                    }));
                    setErrorMessage("Enter your email");
                    break;
                case "CONTENT":
                    setFormErrors(prevFormErrors => ({
                        ...prevFormErrors,
                        content: true
                    }));
                    setErrorMessage("Please give us some details of your inquiry");
                    break;
                default: ;
            }
        }
    }

    return (
        <div className="footer">
            <div className="footerContainer">
                <div className="form">
                    <h1>Like what you see?</h1>
                    <div className="divider"></div>
                    <h3>Fill out this simple form and we will get in contact with you.</h3>
                    <div className="inputRow">
                        <div className="inputContainer">
                            <input name="firstName" id="FirstName" placeholder="First Name:" value={formData.firstName} onChange={handleOnFormChange} />
                            {formErrors.firstName ? <div id="FirstNameBorder" className="inputBorderError"></div> : <div id="FirstNameBorder" className="inputBorder"></div>}
                        </div>
                        <div className="inputContainer">
                            <input name="lastName" id="LastName" placeholder="Last Name:" value={formData.lastName} onChange={handleOnFormChange} />
                            {formErrors.lastName ? <div id="LastNameBorder" className="inputBorderError"></div> : <div id="LastNameBorder" className="inputBorder"></div>}
                        </div>
                    </div>
                    <div className="inputRow lastRow">
                        <div className="inputContainer">
                            <input name="phoneNumber" id="PhoneNumber" placeholder="Phone Number:" value={formData.phoneNumber} onChange={handleOnFormChange} />
                            {formErrors.phoneNumber ? <div id="PhoneNumberBorder" className="inputBorderError"></div> : <div id="PhoneNumberBorder" className="inputBorder"></div>}
                            <div id="PhoneNumberBorder" className="inputBorder"></div>
                        </div>
                        <div className="inputContainer">
                            <input name="email" id="Email" placeholder="Email:" value={formData.email} onChange={handleOnFormChange} />
                            {formErrors.email ? <div id="PhoneNumberBorder" className="inputBorderError"></div> : <div id="PhoneNumberBorder" className="inputBorder"></div>}
                        </div>
                    </div>
                    <div className="inputRow" style={{ marginBottom: "15px" }}>
                        <textarea name="content" id="Content" placeholder="What can we do for you?" value={formData.content} onChange={handleOnFormChange}></textarea>
                        {formErrors.content ? <div id="ContentBorder" className="inputBorderErrorTextArea"></div> : <div id="ContentBorder" className="inputBorder"></div>}
                    </div>
                    {/* Add Loading circle when the submit button is clicked */}
                    <div id="Message"></div>
                    <div id="SuccessMessage">{successMessage}</div>
                    <div id="ErrorMessage">{errorMessage}</div>
                    <div id="submitFooterContainer">
                        {loading ? (
                            <>
                                <div id="SubmitButtonLoad" onClick={submitForm}>Submit</div>
                                <Spinner class={`small white`} />
                            </>
                        ) :
                            <div id="SubmitButton" onClick={submitForm}>Submit</div>
                        }

                    </div>
                </div>
                <div className="footerNav nav">
                    <div className="footerNavItem">
                        <h3>Gallery</h3>
                        <div className="divider footerDivider"></div>
                    </div>
                    <div className="footerNavItem">
                        <NavLink to="/" exact>
                            <div>Home</div>
                            <div className="inputBorder footerDivider"></div>
                        </NavLink>
                    </div>
                    <div className="footerNavItem">
                        <NavLink to="/gallery/backsplash">
                            <div>Backsplash</div>
                            <div className="inputBorder footerDivider"></div>
                        </NavLink>
                    </div>
                    <div className="footerNavItem">
                        <NavLink to="/gallery/showers">
                            <div>Showers</div>
                            <div className="inputBorder footerDivider"></div>
                        </NavLink>
                    </div>
                    <div className="footerNavItem">
                        <NavLink to="/gallery/fireplaces">
                            <div>Fireplaces</div>
                            <div className="inputBorder footerDivider"></div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <NavLink to="/admin">
                <p className="copyright">© <span id="year">{year}</span> Piece By Piece Tile All Rights Reserved</p>
            </NavLink>
        </div>
    )
}

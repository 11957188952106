import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function courseReducer(state = initialState.gallery, action) {
    switch (action.type) {
        case types.LOADED_BACKSPLASH_DATA:
            return { ...state, backsplash: action.data };
        case types.LOADED_SHOWER_DATA:
            return { ...state, showers: action.data };
        case types.LOADED_FIREPLACE_DATA:
            return { ...state, fireplaces: action.data };
        case types.ADD_BACKSPLASH_DATA:
            let backsplashData = state.backsplash;
            backsplashData.push({ imgPath: action.file, credit: action.credit, order: action.order, id: action.id, originalImgPath: action.originalImgPath });
            return { ...state, backsplash: backsplashData };
        case types.ADD_SHOWER_DATA:
            let showerData = state.showers;
            showerData.push({ imgPath: action.file, credit: action.credit, order: action.order, id: action.id, originalImgPath: action.originalImgPath });
            return { ...state, showers: showerData };
        case types.ADD_FIREPLACE_DATA:
            let fireplaceData = state.fireplaces;
            fireplaceData.push({ imgPath: action.file, credit: action.credit, order: action.order, id: action.id, originalImgPath: action.originalImgPath });
            return { ...state, fireplaces: fireplaceData };
        case types.DELETE_BACKSPLASH_ITEM:
            let newBacksplash = state.backsplash.filter(item => item.id !== action.id);
            return { ...state, backsplash: newBacksplash };
        case types.DELETE_SHOWER_ITEM:
            let newShower = state.showers.filter(item => item.id !== action.id);
            return { ...state, showers: newShower };
        case types.DELETE_FIREPLACE_ITEM:
            let newFireplace = state.fireplaces.filter(item => item.id !== action.id);
            return { ...state, fireplaces: newFireplace };
        case types.UPDATE_BACKSPLASH_ITEM:
            let objectKeys = Object.keys(action.data.updatedProperties);
            let newState = state.backsplash;
            newState.forEach(item => {
                if (item.id === action.data.id) {
                    objectKeys.forEach(key => {
                        item[key] = action.data.updatedProperties[key];
                    })
                }
            });
            return { ...state, backsplash: newState };
        case types.UPDATE_SHOWER_ITEM:
            let objectKeys1 = Object.keys(action.data.updatedProperties);
            let newState1 = state.showers;
            newState1.forEach(item => {
                if (item.id === action.data.id) {
                    objectKeys1.forEach(key => {
                        item[key] = action.data.updatedProperties[key];
                    })
                }
            });
            return { ...state, showers: newState1 };
        case types.UPDATE_FIREPLACE_ITEM:
            let objectKeys2 = Object.keys(action.data.updatedProperties);
            let newState2 = state.fireplaces;
            newState2.forEach(item => {
                if (item.id === action.data.id) {
                    objectKeys2.forEach(key => {
                        item[key] = action.data.updatedProperties[key];
                    })
                }
            });
            return { ...state, fireplaces: newState2 };
        case types.CHANGE_BACKSPLASH_ORDER:
            return { ...state, backsplash: action.changedData };
        case types.CHANGE_SHOWER_ORDER:
            return { ...state, showers: action.changedData };
        case types.CHANGE_FIREPLACE_ORDER:
            return { ...state, fireplaces: action.changedData };
        default:
            return state;
    }
}
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';


import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';

// Firebase stuff

const firebaseConfig = {
    apiKey: "AIzaSyDb24nXqpCfh6uFY0G59NtoF1WiwaMjwqc",
    authDomain: "pxptile-82172.firebaseapp.com",
    projectId: "pxptile-82172",
    storageBucket: "pxptile-82172.appspot.com",
    messagingSenderId: "174032235685",
    appId: "1:174032235685:web:b6aeaebe88e9cc7c8eb14c",
    measurementId: "G-92MH046WEG"
}

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.analytics();
firebase.storage();



// Development store

// export default function configureStore(initialState) {
//     const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //add support for Redux dev tools
//     return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
//     // , reduxImmutableStateInvariant() // This caused an error when added to the applyMiddleware function.
//     // Anytime I connected more than one component to the store an infinite loop would occur. Not sure why.
// }


// Production store

export default function configureStore(initialState) {
    return createStore(rootReducer, initialState, applyMiddleware(thunk));
}